var render = function () {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c("AConfirmation", {
        attrs: { isShow: _vm.isPending },
        on: { cancel: _vm.cancelSubmit, confirm: _vm.submit },
      }),
      _c("a-success-index", {
        attrs: { api: this.api },
        on: { close: _vm.redirectCompany },
      }),
      _c(
        "v-card",
        { staticClass: "pb-4" },
        [
          _c(
            "v-toolbar",
            {
              staticClass: "white--text text-h5 mb-4",
              attrs: { color: "secondary" },
            },
            [_vm._v(" New Company ")]
          ),
          _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _c("v-col", { staticClass: "pa-3" }, [
                _c(
                  "div",
                  { staticClass: "d-flex justify-start" },
                  [
                    _c("v-text-field", {
                      attrs: {
                        label: "Company Name ",
                        rules: _vm.requiredRules,
                        dense: "",
                        outlined: "",
                      },
                      model: {
                        value: _vm.companies.company_name,
                        callback: function ($$v) {
                          _vm.$set(_vm.companies, "company_name", $$v)
                        },
                        expression: "companies.company_name",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "d-flex justify-start" },
                  [
                    _c("v-autocomplete", {
                      attrs: {
                        rules: _vm.requiredRules,
                        items: _vm.AccountCompanyOptions,
                        label: "Company Type",
                        dense: "",
                        outlined: "",
                      },
                      model: {
                        value: _vm.companies.account_type,
                        callback: function ($$v) {
                          _vm.$set(_vm.companies, "account_type", $$v)
                        },
                        expression: "companies.account_type",
                      },
                    }),
                  ],
                  1
                ),
                _vm.companies.account_type != null &&
                _vm.companies.account_type != "First"
                  ? _c(
                      "div",
                      { staticClass: "d-flex justify-start" },
                      [
                        _c("v-autocomplete", {
                          attrs: {
                            items: _vm.AccountSubCompanyOptions,
                            label: "Subscription Type",
                            dense: "",
                            outlined: "",
                          },
                          model: {
                            value: _vm.companies.account_sub_type,
                            callback: function ($$v) {
                              _vm.$set(_vm.companies, "account_sub_type", $$v)
                            },
                            expression: "companies.account_sub_type",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "d-flex justify-start" },
                  [
                    _c("v-textarea", {
                      attrs: { label: "Address", dense: "", outlined: "" },
                      model: {
                        value: _vm.companies.company_address,
                        callback: function ($$v) {
                          _vm.$set(_vm.companies, "company_address", $$v)
                        },
                        expression: "companies.company_address",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("v-col", { staticClass: "pa-3" }, [
                _c(
                  "div",
                  { staticClass: "d-flex justify-start" },
                  [
                    _c("v-text-field", {
                      attrs: { label: "Coupon", dense: "", outlined: "" },
                      model: {
                        value: _vm.companies.company_coupon,
                        callback: function ($$v) {
                          _vm.$set(_vm.companies, "company_coupon", $$v)
                        },
                        expression: "companies.company_coupon",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "d-flex justify-start" },
                  [
                    _c("v-text-field", {
                      attrs: { label: "Industry", dense: "", outlined: "" },
                      model: {
                        value: _vm.companies.company_industry,
                        callback: function ($$v) {
                          _vm.$set(_vm.companies, "company_industry", $$v)
                        },
                        expression: "companies.company_industry",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "d-flex justify-start" },
                  [
                    _c("v-text-field", {
                      attrs: { label: "SSM No.", dense: "", outlined: "" },
                      model: {
                        value: _vm.companies.company_ssm,
                        callback: function ($$v) {
                          _vm.$set(_vm.companies, "company_ssm", $$v)
                        },
                        expression: "companies.company_ssm",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _c(
            "div",
            [
              _c(
                "v-card",
                { staticClass: "ma-5 pa-2" },
                [
                  _c("div", { staticClass: "d-flex justify-start mx-2 mt-4" }, [
                    _c("p", { staticClass: "text-h5 font-weight-bold" }, [
                      _vm._v(" User "),
                    ]),
                  ]),
                  _c(
                    "v-row",
                    { attrs: { "no-gutters": "" } },
                    [
                      _c("v-col", [
                        _c("div", { staticClass: "px-2" }, [
                          _c("div", { staticClass: "d-flex flex-row" }, [
                            _c(
                              "div",
                              { staticClass: "flex mr-2" },
                              [
                                _c("v-text-field", {
                                  attrs: {
                                    rules: _vm.requiredRules,
                                    label: "First Name",
                                    dense: "",
                                    outlined: "",
                                  },
                                  model: {
                                    value: _vm.users.user_fname,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.users, "user_fname", $$v)
                                    },
                                    expression: "users.user_fname",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "flex" },
                              [
                                _c("v-text-field", {
                                  attrs: {
                                    rules: _vm.requiredRules,
                                    label: "Last Name",
                                    dense: "",
                                    outlined: "",
                                  },
                                  model: {
                                    value: _vm.users.user_lname,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.users, "user_lname", $$v)
                                    },
                                    expression: "users.user_lname",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _c("div", { staticClass: "d-flex flex-row" }, [
                            _c(
                              "div",
                              { staticClass: "flex mr-2" },
                              [
                                _c("v-text-field", {
                                  attrs: {
                                    rules: _vm.requiredRules,
                                    label: "Email",
                                    dense: "",
                                    outlined: "",
                                  },
                                  model: {
                                    value: _vm.users.email,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.users, "email", $$v)
                                    },
                                    expression: "users.email",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "flex" },
                              [
                                _c("v-text-field", {
                                  attrs: {
                                    rules: _vm.requiredRules,
                                    label: "Mobile",
                                    dense: "",
                                    outlined: "",
                                  },
                                  model: {
                                    value: _vm.users.mobile,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.users, "mobile", $$v)
                                    },
                                    expression: "users.mobile",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _c(
                            "div",
                            [
                              _c("v-autocomplete", {
                                attrs: {
                                  dense: "",
                                  outlined: "",
                                  rules: _vm.requiredRules,
                                  items: _vm.AccountUserOptions,
                                  label: "User Account Type",
                                },
                                model: {
                                  value: _vm.users.user_account_type,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.users,
                                      "user_account_type",
                                      $$v
                                    )
                                  },
                                  expression: "users.user_account_type",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "d-flex justify-start" },
                            [
                              _c("v-autocomplete", {
                                attrs: {
                                  items: _vm.RoleOptions,
                                  rules: _vm.requiredRules,
                                  dense: "",
                                  outlined: "",
                                  label: "Role",
                                },
                                model: {
                                  value: _vm.users.user_role,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.users, "user_role", $$v)
                                  },
                                  expression: "users.user_role",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "d-flex justify-start" },
                            [
                              _c("v-autocomplete", {
                                attrs: {
                                  items: _vm.AAERoleOptions,
                                  dense: "",
                                  outlined: "",
                                  label: "AAE Role",
                                },
                                model: {
                                  value: _vm.users.aae_role,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.users, "aae_role", $$v)
                                  },
                                  expression: "users.aae_role",
                                },
                              }),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "d-flex flex-row" }, [
                            _c(
                              "div",
                              { staticClass: "flex mr-2" },
                              [
                                _c("v-autocomplete", {
                                  attrs: {
                                    rules: _vm.requiredRules,
                                    items: _vm.MainCategoryOptions,
                                    clearable: "",
                                    dense: "",
                                    outlined: "",
                                    label: "Main Category",
                                  },
                                  on: { change: _vm.updateMainCategory },
                                  model: {
                                    value: _vm.users.main_category,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.users, "main_category", $$v)
                                    },
                                    expression: "users.main_category",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm.users.main_category === "TaxPOD Client"
                              ? _c(
                                  "div",
                                  { staticClass: "flex" },
                                  [
                                    _c("v-autocomplete", {
                                      attrs: {
                                        items: _vm.TaxPODClientOptions,
                                        dense: "",
                                        outlined: "",
                                        label: "Sub category",
                                      },
                                      model: {
                                        value: _vm.users.sub_category,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.users,
                                            "sub_category",
                                            $$v
                                          )
                                        },
                                        expression: "users.sub_category",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.users.main_category === "Internal Staff"
                              ? _c(
                                  "div",
                                  { staticClass: "flex" },
                                  [
                                    _c("v-autocomplete", {
                                      attrs: {
                                        items: _vm.InternalStaffOptions,
                                        dense: "",
                                        outlined: "",
                                        label: "Sub Category",
                                      },
                                      model: {
                                        value: _vm.users.sub_category,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.users,
                                            "sub_category",
                                            $$v
                                          )
                                        },
                                        expression: "users.sub_category",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.users.main_category === null ||
                            _vm.users.main_category === "SmartCFO Client"
                              ? _c(
                                  "div",
                                  { staticClass: "flex" },
                                  [
                                    _c("v-autocomplete", {
                                      attrs: {
                                        disabled: "",
                                        items: _vm.TaxPODClientOptions,
                                        dense: "",
                                        outlined: "",
                                        label: "Sub category",
                                      },
                                      model: {
                                        value: _vm.users.sub_category,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.users,
                                            "sub_category",
                                            $$v
                                          )
                                        },
                                        expression: "users.sub_category",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "d-flex justify-end pt-5 px-5" },
            [
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", plain: "" },
                  on: {
                    click: function () {
                      _vm.$router.go(-1)
                    },
                  },
                },
                [_vm._v(" Cancel ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: { loading: _vm.api.isLoading, color: "primary" },
                  on: {
                    click: function () {
                      this$1.isPending = true
                    },
                  },
                },
                [_vm._v(" Confirm ")]
              ),
            ],
            1
          ),
          _c("AError", { staticClass: "mt-3 mx-4", attrs: { api: this.api } }),
          _vm.nameExisted
            ? _c(
                "div",
                { staticClass: "d-flex justify-end px-4 pb-4" },
                [
                  _c(
                    "v-alert",
                    {
                      staticClass: "mt-3",
                      attrs: {
                        width: "50%",
                        density: "compact",
                        type: "warning",
                      },
                    },
                    [
                      _c("v-spacer"),
                      _c("div", [
                        _vm._v(
                          " There is already existed company with the same name. Do you still want to register this company ? "
                        ),
                      ]),
                      _c(
                        "div",
                        { staticClass: "d-flex justify-end mt-4" },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "mx-2 font-weight-bold",
                              attrs: {
                                color: "primary",
                                outlined: "",
                                to: {
                                  name: "PageCompaniesDetail",
                                  params: { id: this.existedCompanyId },
                                },
                                plain: "",
                              },
                            },
                            [_vm._v(" Check Company ")]
                          ),
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "primary" },
                              on: { click: _vm.createNewCompany },
                            },
                            [_vm._v(" Confirm Register ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }